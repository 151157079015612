import React, {Component} from 'react';
//import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronDoubleDown } from '@fortawesome/pro-light-svg-icons';
import _ from'lodash';



const INITIAL_STATE = {
    title:'',
    elevatorType: '',
    manufacture: '',
    language: '',
};


class ManufactureFilter extends Component {
    constructor(props, location){
        super(props, location);
        this.dataArray = Object.keys(props.array).map(i => props.array[i]);
        this.state = { ...INITIAL_STATE }
    };

    removeNull(variable){
        for ( const prop in variable ){
            if (variable[prop] === null || variable[prop] === undefined || variable[prop] === '' ){
                delete variable[prop];
            }
        }
        return variable;

    }

    componentDidMount() {

        var that = this;

        var rollDown = document.querySelector('.rollDown');
        document.getElementById('rollDownLabel').onclick = function(e){
            rollDown.classList.toggle('open');
        };

        var x, i, j, selElmnt, a, b, c;//var selLabel;
        x = document.getElementsByClassName("filterGroup");
        // for each filterGroup
        for (i = 0; i < x.length; i++) {
            selElmnt = x[i].getElementsByTagName("select")[0];
            //selLabel = selElmnt.previousElementSibling;
            a = document.createElement("DIV");
            a.setAttribute("class", "select-selected");
            a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;

            x[i].appendChild(a);
            b = document.createElement("DIV");
            b.setAttribute("class", "select-items select-hide");

            for (j = 1; j < selElmnt.length; j++) {
                // For each option in the original select element create a new DIV that will act as an option item:
                c = document.createElement("DIV");
                c.innerHTML = selElmnt.options[j].innerHTML;
                c.addEventListener("click", function(e) {
                    // When an item is clicked, update the original select box, and the selected item:
                    var y, i, k, s, h, u;
                    s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                    u = s.previousElementSibling;
                    h = this.parentNode.previousSibling;
                    u.style.opacity = "1";
                    for (i = 0; i < s.length; i++) {
                        if (s.options[i].innerHTML === this.innerHTML) {
                            s.selectedIndex = i;
                            h.innerHTML = this.innerHTML;
                            y = this.parentNode.getElementsByClassName("same-as-selected");
                            for (k = 0; k < y.length; k++) {
                                y[k].removeAttribute("class");
                            }
                            u.style.opacity = "0";
                            this.setAttribute("class", "same-as-selected");
                            // have onChange happen here too
                            //this.setState({ [event.target.name]: event.target.value });
                            //s.setState({ [s.id]: this.innerHTML});
                            that.setState({ [s.id]: this.innerHTML});

                            break;
                        }
                    }
                    h.click();
                });
                b.appendChild(c);
            }
            x[i].appendChild(b);

            a.addEventListener("click", function(e) {

                e.stopPropagation();
                closeAllSelect(this);
                this.nextSibling.classList.toggle("select-hide");
                this.classList.toggle("select-arrow-active");
            });

        }

        function closeAllSelect(elmnt) {

            var x, y, i, arrNo = [];
            x = document.getElementsByClassName("select-items");
            y = document.getElementsByClassName("select-selected");
            for (i = 0; i < y.length; i++) {
                if (elmnt === y[i]) {
                    arrNo.push(i)
                } else {
                    y[i].classList.remove("select-arrow-active");
                }
            }
            for (i = 0; i < x.length; i++) {
                if (arrNo.indexOf(i)) {
                    x[i].classList.add("select-hide");
                }
            }
        }

        document.addEventListener("click", closeAllSelect);

    }

    onSubmit = event => {
        event.preventDefault();
        this.removeNull(this.state);

        const filterArray = this.dataArray;
        const options = this.state;
        var filterTitle = [];

        //---------------------This can be cleaned up---------------------------------------------------

        // var filteredType = filterArray.map( (type) => {
        //     if (type.elevatorType[0].title === options.elevatorType) { return type}
        //     return null;
        // } );
        // filteredType = filteredType.filter( function(element){ return element !== undefined} );
        var filterLangue = filterArray.filter( function(element){ return element.elevatorType[0].title === options.elevatorType} );

        //-----------------This would be a better way, does not include undefined elements-------------------------------
        var filteredType = filterArray.filter( function(element){ return element.language[0].title === options.language} );


        if(options.title !== '' && options.title !== undefined){
             filterTitle = filterArray.filter( element => element.title.toUpperCase().includes(options.title.toUpperCase()) );
        }

        var results = [filteredType, filterLangue, filterTitle];
        results = results.filter( function(element){ return element.length} );



        const fullFilter = _.intersection.apply(_, results);



        console.log(fullFilter);


        this.props.onFilterChange(fullFilter);
        event.preventDefault();

    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onClearFilter = event => {
        this.props.onFilterChange(this.dataArray);

        event.preventDefault();
    }
    render(){
        const {
            title,
            elevatorType,
            language,
        } = this.state;
        return (
                <div id="filtering">
                    <form onSubmit={this.onSubmit}>
                    <div className="form-group password-group">
                        <label htmlFor="title" id="searchLabel">Search Manual Database</label>
                        <div className="relative" id="rel1">
                            <input
                                name='title'
                                value={title}
                                id="title"
                                className="fullInput"
                                onChange={this.onChange}
                                type='text'
                            />
                            <div className="position-end">
                                <label htmlFor="submitSearch" className="pointer"><span className="screen-reader">Search</span><span class="Search">Search <FontAwesomeIcon className="search" icon={faSearch} /></span></label>
                                <button className="visually-hidden" type="submit" name="submitSearch" id="submitSearch" />
                            </div>
                        </div>
                    </div>
                    <fieldset id="rollDown" className="rollDown">
                        <label id="rollDownLabel" htmlFor="rollDown">Filter Search <FontAwesomeIcon className="doubleArrow" icon={faChevronDoubleDown} /></label>
                        <div>
                            <div className="filterGroup">
                                <label htmlFor="elevatorType">Filter Tag Type</label>
                                <select id="elevatorType" name="elevatorType" onBlur={this.onChange} value={elevatorType}>
                                    <option value=''></option>
                                    {this.props.eletype.map((names) => {
                                            return(
                                                <option value={this.elevatorType} key={names.title}>{names.title}</option>
                                            )
                                        })}

                                </select>
                            </div>
                            <div className="filterGroup">
                                <label htmlFor="language">Filter by Language</label>
                                <select id="language" name="language" onBlur={this.onChange} value={language}>
                                        <option value =''></option>
                                        {this.props.language.map( (name) =>{
                                            return(
                                                <option value={this.language} key={name.title}>{name.title}</option>
                                            )
                                        })}
                                </select>
                            </div>

                            <button type="submit">Apply Filters</button>
                            <button onClick={this.onClearFilter}> Clear Filter </button>
                            </div>
                        </fieldset>
                    </form>

                </div>
        )
    }


}

export default ManufactureFilter;
