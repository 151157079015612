import React from 'react';

import ManualList from './ManualList';
import ManufactureFilter from './ManufactureFilter';

class ManufactureLanding extends React.Component{
    constructor(props){
        super(props);
        const filterCompany = props.array.entries.filter(function(element){ return element.elevatorManufacturer[0].title === props.manufacturer});
        this.state = {array: filterCompany};
    }

    onFilterChange(filter){
        this.setState({
            array: filter
        })
    }

    componentDidMount(){
        this.setState(this.state.array);
    }


    render(){
        return(
            <div className="clear-both">
                <ManufactureFilter array={this.state.array} manufacture={this.props.array.manufacture} eletype={this.props.array.type} language={this.props.array.langue} onFilterChange={this.onFilterChange.bind(this)}  />
                <ManualList array={this.state.array}/>
            </div>

        )
    }
}

export default ManufactureLanding
