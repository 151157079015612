import React from 'react';
import { Link } from "gatsby";
import * as ROUTES from '../constants/routes';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';

import Layout from '../App/components/layout';
import ManufctureLanding from '../App/components/ManufactureLanding';
import SEO from "../App/components/seo"
const manufactureLandingPage = (props) => {
    return(
        <Layout>
            <SEO title="CECA-ACEA MCP" />
            <div className="wrapper">
                <Link to={ROUTES.INDEX} className="back-btn"><FontAwesomeIcon className="back" icon={faArrowLeft} />Return to Manuals</Link>
                {props.pageContext.asset ? <img className="heroImg" src={props.pageContext.asset} alt=""/> : <></>}
                <div id="manuInfo">
                    <h1>{props.pageContext.title}</h1>

                    {props.pageContext.address ? <p>{ props.pageContext.address }</p> : <></> }

                    {props.pageContext.phone ? <p>{ props.pageContext.phone }</p> : <></> }

                    {props.pageContext.url ? <p><a href={ props.pageContext.url } className="btn button ghost" target="_blank" rel="noopener noreferrer">Visit {props.pageContext.title} Support Site</a></p> : <></> }

                    {props.pageContext.body ? <div dangerouslySetInnerHTML={{__html: props.pageContext.body}} /> : <></> }
                </div>
                <ManufctureLanding array={props.pageContext.data} manufacturer={props.pageContext.title}/>
            </div>
        </Layout>
    )
}

export default manufactureLandingPage
